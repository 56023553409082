<template>
  <b-row class="justify-content-center">
    <b-col md="6" xl="6">
      <b-card class="mb-5">
        <b-overlay :show="loading" rounded="sm">
          <h4 class="my-2">Penanggung Jawab Merchant</h4>
          <b-row>
            <b-col md="6" xl="6">
              <b-form-group label="Nama Lengkap" label-for="name">
                <b-form-input id="name" v-model="form.name" />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="6">
              <b-form-group label="No. Handphone" label-for="phone">
                <b-form-input
                  id="phone"
                  maxLength="13"
                  type="number"
                  v-model="form.phone"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="6">
              <b-form-group label="NIK E-KTP" label-for="id-card">
                <b-form-input
                  id="id-card"
                  maxLength="16"
                  type="number"
                  v-model="form.nik"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="6">
              <b-form-group label="Email" label-for="email">
                <b-form-input id="email" v-model="form.email" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col xl="12">
              <b-form-group label="Alamat Tempat Tinggal" label-for="address">
                <b-form-textarea id="address" v-model="form.address" />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="6">
              <b-form-group label="Kode Pos" label-for="postal-code">
                <b-form-input
                  id="postal-code"
                  v-model="form.postal_code"
                  type="number"
                  maxlength="5"
                  v-on:input="onPostalCodeChange"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="6">
              <b-form-group label="Desa / Kelurahan" label-for="village">
                <b-form-input id="village" v-model="form.village" />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="6">
              <b-form-group label="Kecamatan" label-for="district">
                <b-form-input id="email" v-model="form.district" />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="6">
              <b-form-group label="Kota / Kabupaten" label-for="city">
                <b-form-input id="city" v-model="form.city" />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="6">
              <b-form-group label="Provinsi" label-for="province">
                <b-form-input id="province" v-model="form.province" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-button block class="mt-2" variant="success" @click="onSend"
                >Selanjutnya</b-button
              >
            </b-col>
          </b-row>
        </b-overlay>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import merchant from "@/router/routes/merchant";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BButton,
    BOverlay,
  },
  data() {
    return {
      merchantId: null,
      businessType: null,
      loading: false,
      postal_codes: [],
      form: {
        name: null,
        phone: null,
        nik: "",
        email: null,
        address: null,
        village: null,
        district: null,
        city: null,
        province: null,
        postal_code: "",
      },
    };
  },
  created() {
    this.merchantId = this.$store.state.app.merchantId;

    if (this.$store.state.app.merchantId === null) {
      this.merchantId = localStorage.getItem("merchantId", this.merchantId);
    }

    this.businessType = this.$store.state.app.businessType;

    if (this.$store.state.app.businessType === null) {
      this.businessType = localStorage.getItem(
        "businessType",
        this.businessType
      );
    }
  },
  methods: {
    getPostalCode($code) {
      this.$http;
      this.$http
        .get("/merchant/postal-codes?code=" + $code)
        .then((response) => {
          let $data = response.data.data;

          if ($data != null) {
            this.form.village = $data.village;
            this.form.district = $data.district;
            this.form.city = $data.city;
            this.form.province = $data.province;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSend() {
      if (this.validation()) {
        this.loading = true;
        this.$http
          .post("/merchants/" + this.merchantId + "/profile/add", this.form)
          .then((res) => {
            this.loading = false;
            this.$bvToast.toast("Berhasil", {
              title: "Berhasil",
              variant: "success",
              solid: true,
              autoHideDelay: 1000,
            });
            this.$store.state.app.merchantId = this.merchantId;
            this.$store.state.app.businessType = this.businessType;
            localStorage.setItem("merchantId", this.merchantId);
            localStorage.setItem("businessType", this.businessType);
            this.$router.push("/merchant/add/bank");
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
            this.$bvToast.toast(err.response.data.message, {
              title: "Error",
              variant: "danger",
              solid: true,
              autoHideDelay: 1000,
            });
          });
      }
    },
    makeToast(variant = null) {
      this.$bvToast.toast("Toast body content", {
        title: `Variant ${variant || "default"}`,
        variant,
        solid: true,
      });
    },
    onPostalCodeChange(i) {
      if (i.length == 5) {
        this.getPostalCode(i);
      } else {
        this.form.village = null;
        this.form.district = null;
        this.form.city = null;
        this.form.province = null;
      }
    },
    validation() {
      if (this.form.name === null) {
        this.$bvToast.toast("Nama tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.phone === null) {
        this.$bvToast.toast("No Handphone tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.phone.length < 10) {
        this.$bvToast.toast("No Handphone min. 10 angka", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.phone.length === 0) {
        this.$bvToast.toast("Nama tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.nik.length === 0) {
        this.$bvToast.toast("NIK E-KTP tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.nik.length !== 16) {
        this.$bvToast.toast("NIK E-KTP harus 16 digit", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.email === null) {
        this.$bvToast.toast("Email tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.email !== null && !this.form.email.includes("@")) {
        this.$bvToast.toast("Format email belum sesusai", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.address === null) {
        this.$bvToast.toast("Alamat tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.postal_code.length === 0) {
        this.$bvToast.toast("Kode pos tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.postal_code.length !== 5) {
        this.$bvToast.toast("Kode pos harus 5 Angka", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.village === null) {
        this.$bvToast.toast("Desa/Kelurahan tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.district === null) {
        this.$bvToast.toast("Kecamatan tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.city === null) {
        this.$bvToast.toast("Kota/Kabupaten tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.province === null) {
        this.$bvToast.toast("Provinsi tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
